import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const Company = () => (
  <Layout>
    <SEO title="Company" />
    <div>
      <h1>Company</h1>
      <p>An overview of our company and stuff.</p>
    </div>
  </Layout>
)

export default Company
